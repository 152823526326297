import { useEffect, useState } from 'react';

export const useDebounce = <T>(key: T, time: number) => {
	const [lastKey, setLastKey] = useState(key);

	useEffect(() => {
		if (key !== lastKey) {
			const timeout = setTimeout(() => {
				setLastKey(key);
			}, time);

			return () => clearTimeout(timeout);
		}
	}, [key, lastKey, time]);

	return lastKey;
};
