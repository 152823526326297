export enum ExperienceTypes {
	Load = 'load',
	Experience = 'experience',
	Operation = 'operation',
}

export enum ExperiencePerformanceTypes {
	PageLoad = 'page-load',
	PageSegmentLoad = 'page-segment-load',
	InlineResult = 'inline-result',
	Custom = 'custom',
}
