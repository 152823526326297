import memoize from 'lodash/memoize';

import type { EmojiResourceConfig, EmojiResource, EmojiProvider } from '@atlaskit/emoji/resource';

import { getLogger } from '@confluence/logger';

export type EmojiSSRConfig = Pick<EmojiResourceConfig, 'optimisticImageApi'>;

export type ConfigOptions = {
	cloudId?: string | null;
	userId?: string | null;
	disableUpload?: boolean;
	emojiSSRConfig?: EmojiSSRConfig;
	/**
	 * This option will force load site emoji resource
	 * regardless current user's auth status.
	 */
	allowAnonymousAccess?: boolean;
};
const logger = getLogger('emoji-provider');

export const BASE_URL = '/gateway/api/emoji';

export const getEmojiConfig = (
	cloudId?: string,
	userId?: string,
	disableUpload?: boolean,
	emojiSSRConfig?: EmojiSSRConfig,
	allowAnonymousAccess?: boolean,
): EmojiResourceConfig => {
	const currentUser = userId ? { id: userId } : undefined;

	const config = {
		providers: [
			{
				url: `${BASE_URL}/standard`,
			},
			{
				url: `${BASE_URL}/atlassian`,
			},
		],
		allowUpload: !!userId && !disableUpload,
		currentUser,
	};

	if (cloudId && (userId || allowAnonymousAccess)) {
		config.providers.push({
			url: `${BASE_URL}/${cloudId}/site`,
		});
	}

	if (emojiSSRConfig) {
		return { ...config, ...emojiSSRConfig };
	}

	return config;
};

type EmojiResourceConstructor = typeof EmojiResource;

export const resolverOfMemoize = (_EmojiResource: any, extraConfig?: ConfigOptions) => {
	const cloudId = extraConfig?.cloudId ?? '';
	const userId = extraConfig?.userId ?? '';
	const allowAnonymousAccess = extraConfig?.allowAnonymousAccess ?? false;
	return `${cloudId}_${userId}_${allowAnonymousAccess}`;
};

export const getEmojiResource = memoize(
	(EmojiResource: EmojiResourceConstructor, extraConfig?: ConfigOptions): EmojiResource => {
		const cloudId = extraConfig?.cloudId ?? '';
		const userId = extraConfig?.userId ?? undefined;
		const disableUpload = extraConfig?.disableUpload;
		const emojiSSRConfig = extraConfig?.emojiSSRConfig ?? undefined;
		const allowAnonymousAccess = extraConfig?.allowAnonymousAccess ?? undefined;
		const emojiConfig = getEmojiConfig(
			cloudId,
			userId,
			disableUpload,
			emojiSSRConfig,
			allowAnonymousAccess,
		);

		return new EmojiResource(emojiConfig);
	},
	resolverOfMemoize,
);

export const getEmojiProvider = memoize(
	(
		EmojiResource: EmojiResourceConstructor,
		extraConfig?: ConfigOptions,
	): Promise<EmojiProvider> => {
		try {
			const emojiResource = getEmojiResource(EmojiResource, extraConfig);

			return emojiResource.getEmojiProvider();
		} catch (err) {
			/* istanbul ignore next */
			if (process.env.NODE_ENV !== 'production') {
				logger.error`Error creating emoji resource ${err}`;
			}

			return Promise.resolve(undefined as any);
		}
	},
	resolverOfMemoize,
);
