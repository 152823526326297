import gql from 'graphql-tag';

export const ContentTreeSearchQuery = gql`
	query ContentTreeSearchQuery(
		$experience: String!
		$searchText: String!
		$first: Int
		$filters: SearchFilterInput!
	) {
		search {
			search(experience: $experience, query: $searchText, first: $first, filters: $filters) {
				edges {
					node {
						id
						title
						type
						... on SearchConfluencePageBlogAttachment {
							entityId
							confluenceEntity {
								... on ConfluencePage {
									id
									metadata {
										titleEmojiPublished {
											id
											value
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
