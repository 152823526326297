import gql from 'graphql-tag';

const emojiTitleInnerFragment = gql`
	fragment emojiTitleInnerFragment on PaginatedJsonContentPropertyList {
		nodes {
			id
			key
			value
			version {
				number
			}
		}
	}
`;

export const getEmojiTitleProperty = gql`
	query getEmojiTitleProperty(
		$contentId: ID
		$status: [String]
		$draftShareId: String
		$skipPublishedFragment: Boolean!
	) {
		content(id: $contentId, draftShareId: $draftShareId, status: $status) {
			nodes {
				id
				emojiTitlePublished: properties(key: "emoji-title-published")
					@skip(if: $skipPublishedFragment) {
					...emojiTitleInnerFragment
				}
				emojiTitleDraft: properties(key: "emoji-title-draft") {
					...emojiTitleInnerFragment
				}
			}
		}
	}

	${emojiTitleInnerFragment}
`;
